
import React,{useEffect,useState} from 'react';

import { StyleSheet,Platform, Text, View, Dimensions } from 'react-native';
import NavigatorHome from './application/navigations/navigatorHome'




global.usuario = null

global.endpoint = 'https://api.dev.gopick-app.com/'



const App = (props:any) => {
	const [paginaCargada, setPaginaCargada] = useState(false);
	
	useEffect(() => {
		setPaginaCargada(true)
  }, []);

   
	
	return (

	    <View style={{flex: 1}}>
	    	{paginaCargada?(
		      	<NavigatorHome></NavigatorHome>

	    	):(null)}
		    
	    </View>          
	);
};


export default (App);