import React, { useState, useEffect } from 'react';
import { Alert,ActivityIndicator,Platform, Dimensions, View, StyleSheet, Image, ImageBackground } from 'react-native';

import * as Animatable from 'react-native-animatable';

import { styles } from '../../assets/styles/styles';
import Logo from "../../assets/icons/logo-gopick"
import { StatusBar } from 'expo-status-bar';


const splashScreen = (props:any) => {
  let [animating, setAnimating] = useState(true);

  let estiloE = 'fadeIn';
  let estiloS = '';
  let duracionE = 1000;
  let duracionS = 0;
  let delayI = 100;
  let delayT = 0;
  let [finishFirst, setFirst] = useState(false);
   
  function cargarSiguienteScreen(){
        props.navigation.reset({
          index:0,
          routes:[{name:'vistaEscanerCashLess'}]
        })
  }
  return (
    <View style={{flex:1}}> 
      <View style={[styles.image,{alignItems:'center',justifyContent: 'center'}]}> 
          <Animatable.View
          animation={finishFirst ? estiloS : estiloE}
          duration={finishFirst ? duracionS : duracionE}
          delay={delayI}
          style={[finishFirst ? styles.imagenAnimada : {},{width:200,height:200}]}
          onAnimationEnd={() => {  
            setTimeout(() => { 
              cargarSiguienteScreen();
            }, 500);
          }}
          >

          
          <Logo width="143" height="105"/>

        </Animatable.View>

      </View>
      <StatusBar style="light" animated={true}/>
    </View>
  );
};

export default splashScreen;


/*<ImageBackground imageStyle={{resizeMode:'contain'}} source={require('../../assets/splash.png')}  style={[styles.image,{alignItems:'center',justifyContent: 'center'}]}>*/