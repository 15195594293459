import React, { useState, useEffect, useRef } from 'react';
import splashScreen from '../screens/splashScreen';

import vistaEscanerCashLess from '../screens/vistaEscanerCashLess';

import {Button, Text, TouchableOpacity, View, StatusBar} from "react-native";
import { NavigationContainer, getFocusedRouteNameFromRoute, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { TransitionPresets } from '@react-navigation/stack';
import {Octicons,MaterialIcons,AntDesign,EvilIcons,FontAwesome   } from '@expo/vector-icons';
const Stack = createStackNavigator();




const config = {
  animation: 'spring',
  config: {
    stiffness: 1000,
    damping: 500,
    mass: 3,
    overshootClamping: true,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
};


const forFade = ({ current }:any) => ({
    cardStyle: {
      opacity: current.progress,
    },
});


function MyStack(){
  const [switchValue, setSwitchValue] = useState(false);
 
  return (
    <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            gestureEnabled:true,
            gestureDirection:"horizontal",
            headerTitleStyle: {
              color:"white",
              fontSize:18
            },
            headerTintColor: 'white',
            headerStyle:{
                backgroundColor: "#960012",
                height:80,
            },
            headerBackTitleVisible: false,
            headerTitleAlign:'center',
            
          }}>
          <Stack.Screen name="splashScreen" component={splashScreen} options={{headerShown: false,gestureEnabled: false, cardStyleInterpolator: forFade}}  />
          <Stack.Screen name="vistaEscanerCashLess" component={vistaEscanerCashLess} options={{gestureEnabled: false}}  />
        </Stack.Navigator>
      </NavigationContainer>
  );
};
export default MyStack;
