import React, { useState, useEffect } from 'react';
import { Alert,Platform,StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Camera } from 'expo-camera';
//import { BarCodeScanner } from 'expo-barcode-scanner';

import {FontAwesome,Ionicons,AntDesign} from '@expo/vector-icons';

import { BarCodeScanner } from 'expo-barcode-scanner';
import * as Linking from 'expo-linking';


const vistaEscanerCashLess = (props:any) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [type, setType] = useState(Camera.Constants.Type.back);
  
  const [barCodeType, setBarCodeType] = useState([BarCodeScanner.Constants.BarCodeType.qr]);

  const [horizontal, setHorizontal] = useState(true);
  const [uuid, setUuid] = useState('');
  
  const [cashLess, setCashLess] = useState('');
  const [creditoRestante, setCreditoRestante] = useState(0);
  
  function borrarCashLess(){

    setBarCodeType([BarCodeScanner.Constants.BarCodeType.qr])
    setCashLess('')
    props.navigation.replace('vistaEscanerCashLess');
  }
  function devolverCredito(){
    Linking.openURL('https://docs.google.com/forms/d/e/1FAIpQLSfdyRCfcqcK8IOn7GmbwFwljKQNIvlRDbpyZgUwSULKn9eC0w/viewform?entry.1825285377='+uuid);
  }

  useEffect(() => {
     
    /*(async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();*/
      
     props.navigation.setOptions({
             
            
            headerTitle: () => (
              <Text style={{color:'white',fontSize:18,textAlign:'center'}}>Acerca el QR a la cámara para escanearlo</Text>
            ),
        });
    
  }, []);

  
function cargarDatosPulsera(qr_code){
  return new Promise(function(resolve, reject) {
    let url = 'https://sheetdb.io/api/v1/453ldfu958mx7/search?sheet=qr_codes&single_object=true&user_qr_code='+qr_code
    console.log("cargarDatosPulsera ", url)
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
          console.log('devuelve llamada result--->', response.status)
          if (response.status == 200) {
              resolve(response.json());
          }else{
              reject();
          }
      })
      .catch((error) => {
          console.error('Error cargarTiendas -> '+error);
          reject(error);
      })
    });
}

  function onBarCodeRead (data:any) {
      
      let datastr = data.trim()
      setUuid(datastr)

      setBarCodeType([''])
      cargarDatosPulsera(datastr).then(function(res:any){
        console.log("res",res)
        setCashLess(res)
        setBarCodeType([''])
        if(res.amount_remaining==undefined){
           alert("El código escaneado no es válido")
           props.navigation.replace('vistaEscanerCashLess');
        }
        setCreditoRestante(res.amount_remaining || 0)
      },function(err){
        alert("El código escaneado no es válido")
        console.log("error",err)
        props.navigation.replace('vistaEscanerCashLess');
      })
      
  }

  return (
    <View style={[{flex: 1,backgroundColor:'black'}]}>
      <Camera 
        style={[styles.camera]}
        type={type} 
        onBarCodeScanned={({ type, data }:any ) =>{
          if(data!=undefined && data!=null && data!=''){
            onBarCodeRead(data)
          }
        }}
        barCodeScannerSettings={{
          barCodeTypes: barCodeType,
        }}
        >
      </Camera>
       {
              cashLess!='' ?
                (
                  <View style={{backgroundColor:'rgba(0, 0, 0, 0.8)',position:'absolute',width:'100%',height:'100%',justifyContent:'center',alignItems:'center'}}>
                    <View style={{width:'90%',justifyContent:'center',alignItems:'center'}}>
                      <View style={{height:props.height-100,borderColor:'#BABABA',borderWidth:1,backgroundColor:'white',width:'100%',alignItems:'center',maxWidth:400,justifyContent:'center',borderRadius:8}}>
                      <TouchableOpacity  onPress={borrarCashLess} style={{position:'absolute',top:20,right:20,zIndex:2}}>
                        <AntDesign name="close" size={32} color="#960012" />
                      </TouchableOpacity>
                      <View style={{height:'100%',width:'100%',maxWidth:400}}>
                        <View style={{height:'100%',flex:1,width:'100%',marginTop:20}}>
                            <View style={{flex:1,alignItems:'center',justifyContent:'center',marginBottom:20}}>
                                <Text style={{fontSize:28,fontWeight:'bold',color:'#960012',alignItems:'center',justifyContent:'center',textAlign:'center'}}>
                                  Te quedan {creditoRestante/100}€
                                </Text>
                          </View>
                          
                          
                          
                        </View>
                        
                        {creditoRestante>=200?(
                          <View style={{alignItems:'center',flexDirection:'row'}}>
                            <TouchableOpacity onPress={devolverCredito.bind(this)} style={{flex:1,backgroundColor:'#960012',width:'80%',margin:20,marginTop:0,borderRadius:50}}>
                               <Text style={{fontWeight:'bold',color:'white',textAlign:'center',padding:10}}>Solicitar devolución</Text>
                            </TouchableOpacity>
                          </View>
                          ):(
                          null
                          )
                        }
                        
                      </View>
            
                     </View>
                    </View>
                  </View>
                )
              :
                (
                  null
                )
            }
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    flex: 1,
  },
  
});

export default  (vistaEscanerCashLess);
